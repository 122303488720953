import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, completeLogin } from "../auth.actions";
import isAuthenticated from "../isAuthenticated";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router";
import { UIKit } from "@egdeconsulting/ekom_lib";
import { State } from "../../../redux/rootReducer";

const Login = (props: { myLocation: any }) => {
  console.log("myLocation", props.myLocation);
  const dispatch = useDispatch();
  let location = useLocation();

  const userAuthenticated = useSelector((state: State) =>
    isAuthenticated(state.auth.user)
  );

  useEffect(() => {
    if (location.pathname === "/login/response") {
      dispatch<any>(completeLogin());
    } else if (!userAuthenticated) {
      localStorage.setItem("state", props.myLocation.location.pathname);
      dispatch<any>(login());
    }
  });

  let from = sessionStorage.getItem("location") || "/";
  if (userAuthenticated) {
    console.log("userAuth", from);
    return <Navigate to={from} />;
  } else {
    console.log("!userAuth", from);
    return (
      <UIKit.Container>
        <UIKit.Spinner>Logger inn</UIKit.Spinner>
      </UIKit.Container>
    );
  }
};

export default Login;

import React from 'react'

export function ContentManagament() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 31 31" fill="none">
            <rect width="7" height="7" fill="#3028AA" />
            <rect y="12" width="7" height="7" fill="#3028AA" />
            <rect y="24" width="7" height="7" fill="#3028AA" />
            <rect x="12" width="7" height="7" fill="#3028AA" />
            <rect x="12" y="12" width="7" height="7" fill="#3028AA" />
            <rect x="12" y="24" width="7" height="7" fill="#3028AA" />
            <rect x="24" width="7" height="7" fill="#3028AA" />
            <rect x="24" y="12" width="7" height="7" fill="#3028AA" />
            <rect x="24" y="24" width="7" height="7" fill="#3028AA" />
        </svg>
    )
}
import { UIKit } from "@egdeconsulting/ekom_lib";
import React from "react";
import {
  useGetConstructionsPerMonth,
  useGetOrganisationsPerMonth,
  useGetOrganisationsWithConstructionsPerMonth,
  useGetOrganisationsWithContactInfoPerMonth,
  useGetOrganisationsWithProductsPerMonth,
  useGetProductsPerMonth,
  useGetUsersPerMonth,
} from "../hooks/Statistics";
import MonthlyChart from "../components/MonthlyChart";

const Statistics = () => {
  const { data: productsPerMonth, isPending: isFetchingProductsPerMonth } =
    useGetProductsPerMonth();
  const {
    data: constructionsPerMonth,
    isPending: isFetchingConstructionsPerMonth,
  } = useGetConstructionsPerMonth();
  const { data: usersPerMonth, isPending: isFetchingUsersPerMonth } =
    useGetUsersPerMonth();
  const {
    data: organisationsPerMonth,
    isPending: isFetchingOrganisationsPerMonth,
  } = useGetOrganisationsPerMonth();
  const {
    data: organisationsWithContactInfoPerMonth,
    isPending: isFetchingOrganisationsWithContactInfoPerMonth,
  } = useGetOrganisationsWithContactInfoPerMonth();
  const {
    data: organisationsWithProductsPerMonth,
    isPending: isFetchingOrganisationsWithProductsPerMonth,
  } = useGetOrganisationsWithProductsPerMonth();
  const {
    data: organisationsWithConstructionsPerMonth,
    isPending: isFetchingOrganisationsWithConstructionsPerMonth,
  } = useGetOrganisationsWithConstructionsPerMonth();

  return (
    <UIKit.Section>
      <UIKit.Container>
        <h1 className="uk-text-center">Statistikk</h1>

        <div style={{ height: "300px", minWidth: "450px" }}>
          <h2>Infrastrukturobjekter per måned</h2>
          {isFetchingProductsPerMonth ? (
            <UIKit.Spinner>
              Henter registrerte infrastrukturobjekter per måned
            </UIKit.Spinner>
          ) : (
            <>
              <MonthlyChart data={productsPerMonth} />
            </>
          )}
        </div>

        <div style={{ height: "300px", minWidth: "450px", marginTop: "60px" }}>
          <h2>Bygge- og anleggsarbeid per måned</h2>
          {isFetchingConstructionsPerMonth ? (
            <UIKit.Spinner>
              Henter registrerte bygge- og anleggsarbeid per måned
            </UIKit.Spinner>
          ) : (
            <>
              <MonthlyChart data={constructionsPerMonth} />
            </>
          )}
        </div>

        <div style={{ height: "300px", minWidth: "450px", marginTop: "60px" }}>
          <h2>Brukere per måned</h2>
          {isFetchingUsersPerMonth ? (
            <UIKit.Spinner>Henter registrerte bruker per måned</UIKit.Spinner>
          ) : (
            <>
              <MonthlyChart data={usersPerMonth} />
            </>
          )}
        </div>

        <div style={{ height: "300px", minWidth: "450px", marginTop: "60px" }}>
          <h2>Virksomheter per måned</h2>
          {isFetchingOrganisationsPerMonth ? (
            <UIKit.Spinner>
              Henter registrerte virksomheter per måned
            </UIKit.Spinner>
          ) : (
            <>
              <MonthlyChart data={organisationsPerMonth} />
            </>
          )}
        </div>

        <div style={{ height: "300px", minWidth: "450px", marginTop: "60px" }}>
          <h2>Virksomheter med registrert kontaktinfo per måned</h2>
          {isFetchingOrganisationsWithContactInfoPerMonth ? (
            <UIKit.Spinner>
              Henter virksomheter med registrert kontaktinfo per måned
            </UIKit.Spinner>
          ) : (
            <>
              <MonthlyChart data={organisationsWithContactInfoPerMonth} />
            </>
          )}
        </div>

        <div style={{ height: "300px", minWidth: "450px", marginTop: "60px" }}>
          <h2>Virksomheter med registert infrastruktur per måned</h2>
          {isFetchingOrganisationsWithProductsPerMonth ? (
            <UIKit.Spinner>
              Henter virksomheter med registert infrastruktur per måned
            </UIKit.Spinner>
          ) : (
            <>
              <MonthlyChart data={organisationsWithProductsPerMonth} />
            </>
          )}
        </div>

        <div style={{ height: "300px", minWidth: "450px", marginTop: "60px" }}>
          <h2>Virksomheter med registrert bygge- og anleggsarbeid per måned</h2>
          {isFetchingOrganisationsWithConstructionsPerMonth ? (
            <UIKit.Spinner>
              Henter virksomheter med registrert bygge- og anleggsarbeid per
              måned
            </UIKit.Spinner>
          ) : (
            <>
              <MonthlyChart data={organisationsWithConstructionsPerMonth} />
            </>
          )}
        </div>
      </UIKit.Container>
    </UIKit.Section>
  );
};

export default Statistics;

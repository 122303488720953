import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  UPDATE_USERS_BEGIN,
  UPDATE_USERS_FAILURE,
  UPDATE_USERS_SUCCESS,
  FETCH_USER_ROLES_BEGIN,
  FETCH_USER_ROLES_FAILURE,
  FETCH_USER_ROLES_SUCCESS,
  SET_ACTIVE_ROLE,
} from "./user.action";
import { IUser, IUserRoles } from "./IUser";

export type UserState = {
  users?: IUser[];
  fetchUsersLoading: boolean;
  fetchUsersError?: string;
  updateUsersLoading: boolean;
  updateUsersError?: string;

  roles?: IUserRoles[];
  fetchRolesLoading: boolean;
  fetchRolesError?: string;

  activeRole?: IUserRoles;
};

export default function userReducer(
  state: UserState = {
    fetchUsersLoading: false,
    updateUsersLoading: false,
    fetchRolesLoading: false,
  },
  action: any
) {
  switch (action.type) {
    case FETCH_USERS_BEGIN:
      return {
        ...state,
        fetchUsersLoading: true,
        fetchUsersError: undefined,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        fetchUsersLoading: false,
        users: action.payload,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        fetchUsersLoading: false,
        fetchUsersError: action.payload,
      };
    case UPDATE_USERS_BEGIN:
      return {
        ...state,
        updateUsersLoading: true,
        updateUsersError: undefined,
      };
    case UPDATE_USERS_SUCCESS:
      return {
        ...state,
        updateUsersLoading: false,
      };
    case UPDATE_USERS_FAILURE:
      return {
        ...state,
        updateUsersLoading: false,
        updateUsersError: action.payload,
      };
    case FETCH_USER_ROLES_BEGIN:
      return {
        ...state,
        fetchRolesLoading: true,
        fetchRolesError: undefined,
      };
    case FETCH_USER_ROLES_SUCCESS:
      sessionStorage.setItem(
        "active_user_name",
        action.payload.find((r: { type: string }) => r.type === "Person").name
      );
      return {
        ...state,
        fetchRolesLoading: false,
        roles: action.payload,
      };
    case FETCH_USER_ROLES_FAILURE:
      return {
        ...state,
        fetchRolesLoading: false,
        fetchRolesError: action.payload,
      };
    case SET_ACTIVE_ROLE:
      sessionStorage.setItem("active_role", JSON.stringify(action.payload));
      return {
        ...state,
        activeRole: action.payload,
      };
    default:
      return state;
  }
}

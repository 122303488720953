import React from "react";
import "./App.css";
import Root from "./common/layout/containers/root.view";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./common/auth/containers/login";
import Logout from "./common/auth/containers/logout";
import PrivateRoute from "./common/auth/containers/privateRoute";
import Landing from "./common/containers/landing";
import Content from "./content/containers/content";
import OrganisationManagement from "./management/organisation/containers/organisationManagement";
import UserManagement from "./management/user/containers/userManagement";
import Limits from "./management/limits/containers/limits";
import Role from "./common/role/containers/role";
import { createBrowserHistory } from "history";
import Statistics from "./pages/Statistics";

const App = () => {
  let history = createBrowserHistory();
  return (
    <Router>
      <Root>
        <Routes>
          <Route path="/" element={<PrivateRoute element={Landing} />} />
          <Route
            path="/role/admin/*"
            element={<PrivateRoute element={Role} />}
          />
          <Route
            path="/user-management"
            element={<PrivateRoute element={UserManagement} />}
          />
          <Route
            path="/organisation-management"
            element={<PrivateRoute element={OrganisationManagement} />}
          />
          <Route
            path="/content-management"
            element={<PrivateRoute element={Content} />}
          />
          <Route path="/limits" element={<PrivateRoute element={Limits} />} />
          <Route
            path="/statistics"
            element={<PrivateRoute element={Statistics} />}
          />

          <Route path="/login/*" element={<Login myLocation={history} />} />
          <Route path="/logout/*" element={<Logout />} />
        </Routes>
      </Root>
    </Router>
  );
};

export default App;

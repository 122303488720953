import { IOrganisation } from './IOrganisation';
import {
  fetchOrganisationsBegin,
  fetchOrganisationsFailure,
  fetchOrganisationsSuccess,
  updateOrganisationsBegin,
  updateOrganisationsFailure,
  updateOrganisationsSuccess
} from './organisation.actions'
import { NotificationMessages } from '@egdeconsulting/ekom_lib';

const baseUrl: string = process.env.REACT_APP_AUTH_API || '/404?REACT_APP_AUTH_API';

/**
 * Retrieve all organisations
 * @param access_token bearer access token
 */
export function getOrganisations(access_token: string) {
  return (dispatch: any) => {
    dispatch(fetchOrganisationsBegin());
    return fetch(baseUrl.concat('/api/organisation'), {
      headers: new Headers({ Authorization: 'Bearer ' + access_token })
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error(await response.text())
        }
        else return await response.json()
      })
      .then(organisations => {
        dispatch(fetchOrganisationsSuccess(organisations));
        return organisations;
      })
      .catch((error: Error) => dispatch(fetchOrganisationsFailure(NotificationMessages.ORGANISATIONS_FAILED.FETCH, error)));
  }
}

/**
 * Update all fields on the given organisation objects
 * @param access_token bearer access token
 * @param organisations array of organisations with updated organisation objects
 */
export function updateOrganisations(access_token: string, organisations: IOrganisation[]) {
  return (dispatch: any) => {
    dispatch(updateOrganisationsBegin());
    return fetch(baseUrl.concat('/api/organisation/'), {
      method: 'post',
      body: JSON.stringify(organisations),
      headers: new Headers({
        Authorization: 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      })
    }).then(async response => {
      if (!response.ok) {
        throw new Error(await response.text())
      }
    })
      .then(() => dispatch(updateOrganisationsSuccess(NotificationMessages.UPDATEORGANISATION_SUCCESS.FETCH)))
      .catch((error: Error) => dispatch(updateOrganisationsFailure(NotificationMessages.UPDATEORGANISATION_FAILED.FETCH, error)));
  }
}
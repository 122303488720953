import React, { useEffect } from "react";
import isAuthenticated from "../isAuthenticated";
import { State } from "../../../redux/rootReducer";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ element: Component }: any) => {
  const user = useSelector((state: State) => state.auth.user);
  let location = useLocation();

  useEffect(() => {
    sessionStorage.setItem("location", location.pathname);
  });

  return (
    <>{isAuthenticated(user) ? <Component /> : <Navigate to={"/login"} />}</>
  );
};

export default PrivateRoute;

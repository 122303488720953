import { ILimits } from './ILimits';
import {
  fetchLimitsBegin,
  fetchLimitsFailure,
  fetchLimitsSuccess,
  updateLimitsBegin,
  updateLimitsFailure,
  updateLimitsSuccess
} from './limits.actions';
import { NotificationMessages } from '@egdeconsulting/ekom_lib';

const baseUrl: string = process.env.REACT_APP_AUTH_API || '/404?REACT_APP_AUTH_API';

/**
 * Retrieve limits 
 * @param access_token bearer access token
 */
export function getLimits(access_token: string) {
  return (dispatch: any) => {
    dispatch(fetchLimitsBegin());
    return fetch(baseUrl.concat('/api/limits'), {
      headers: new Headers({ Authorization: 'Bearer ' + access_token })
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error(await response.text())
        }
        else return await response.json()
      })
      .then(limits => {
        dispatch(fetchLimitsSuccess(limits));
        return limits;
      })
      .catch((error: Error) => dispatch(fetchLimitsFailure(NotificationMessages.LIMITS_ADMIN_FAILED.FETCH, error)));
  }
}

/**
 * Updates all fields on the given limits object
 * @param access_token bearer access token
 * @param limits updated limits object
 */
export function updateLimits(access_token: string, limits: ILimits) {
  return (dispatch: any) => {
    dispatch(updateLimitsBegin());
    return fetch(baseUrl.concat('/api/limits'), {
      method: 'post',
      body: JSON.stringify(limits),
      headers: new Headers({
        Authorization: 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      })
    }).then(async response => {
      if (!response.ok) {
        throw new Error(await response.text())
      }
    })
      .then(() => {
        dispatch(updateLimitsSuccess(NotificationMessages.UPDATELIMITS_SUCCESS.FETCH));
      })
      .catch((error: Error) => dispatch(updateLimitsFailure(NotificationMessages.UPDATELIMITS_FAILED.FETCH, error)));
  }
}
import { ILimits } from './ILimits';

export const FETCH_LIMITS_BEGIN = 'FETCH_LIMITS_BEGIN';
export const FETCH_LIMITS_SUCCESS = 'FETCH_LIMITS_SUCCESS';
export const FETCH_LIMITS_FAILURE = 'FETCH_LIMITS_FAILURE';

export const UPDATE_LIMITS_BEGIN = 'UPDATE_LIMITS_BEGIN';
export const UPDATE_LIMITS_SUCCESS = 'UPDATE_LIMITS_SUCCESS';
export const UPDATE_LIMITS_FAILURE = 'UPDATE_LIMITS_FAILURE';

export const fetchLimitsBegin = () => ({
  type: FETCH_LIMITS_BEGIN
});
export const fetchLimitsSuccess = (limits: ILimits) => ({
  type: FETCH_LIMITS_SUCCESS,
  payload: limits
});
export const fetchLimitsFailure = (error: string, exception: Error) => ({
  type: FETCH_LIMITS_FAILURE,
  payload: { message: error, detail: exception.message }
});

export const updateLimitsBegin = () => ({
  type: UPDATE_LIMITS_BEGIN
});
export const updateLimitsSuccess = (msg: string) => ({
  type: UPDATE_LIMITS_SUCCESS,
  payload: { message: msg }
});
export const updateLimitsFailure = (error: string, exception: Error) => ({
  type: UPDATE_LIMITS_FAILURE,
  payload: { message: error, detail: exception.message }
});
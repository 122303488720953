import { IContent } from './IContent';

export const FETCH_CONTENTDATA_BEGIN = 'FETCH_CONTENTDATA_BEGIN';
export const FETCH_CONTENTDATA_SUCCESS = 'FETCH_CONTENTDATA_SUCCESS';
export const FETCH_CONTENTDATA_FAILURE = 'FETCH_CONTENTDATA_FAILURE';

export const UPDATE_CONTENTDATA_BEGIN = 'UPDATE_CONTENTDATA_BEGIN';
export const UPDATE_CONTENTDATA_SUCCESS = 'UPDATE_CONTENTDATA_SUCCESS';
export const UPDATE_CONTENTDATA_FAILURE = 'UPDATE_CONTENTDATA_FAILURE';

export const fetchContentDataBegin = () => ({
    type: FETCH_CONTENTDATA_BEGIN
});
export const fetchContentDataSuccess = (contentList: IContent[]) => ({
    type: FETCH_CONTENTDATA_SUCCESS,
    payload: contentList
});
export const fetchContentDataFailure = (error: string, exception: Error) => ({
    type: FETCH_CONTENTDATA_FAILURE,
    payload: { message: error, detail: exception.message }
});

export const updateContentDataBegin = () => ({
    type: UPDATE_CONTENTDATA_BEGIN
});
export const updateContentDataSuccess = (msg: string) => ({
    type: UPDATE_CONTENTDATA_SUCCESS,
    payload: { message: msg }
});
export const updateContentDataFailure = (error: string, exception: Error) => ({
    type: UPDATE_CONTENTDATA_FAILURE,
    payload: { message: error, detail: exception.message }
});
import { User } from 'oidc-client';

const validScope = process.env.REACT_APP_OIDC_SCOPE || '';

export default function isAuthenticated(user?: User): boolean {
    if (user) {
        if (user.scope === validScope && user.access_token && !user.expired) {
            return true;
        }
    }
    return false;
}
import {
  FETCH_ORGANISATIONS_BEGIN,
  FETCH_ORGANISATIONS_FAILURE,
  FETCH_ORGANISATIONS_SUCCESS,
  UPDATE_ORGANISATIONS_BEGIN,
  UPDATE_ORGANISATIONS_FAILURE,
  UPDATE_ORGANISATIONS_SUCCESS
} from './organisation.actions'
import { IOrganisation } from './IOrganisation';

export type OrganisationState = {
  organisations?: IOrganisation[];
  fetchOrganisationsLoading: boolean;
  fetchOrganisationsError?: string;
  updateOrganisationsLoading: boolean;
  updateOrganisationsError?: string;
}

export default function organisationReducer(
  state: OrganisationState = {
    fetchOrganisationsLoading: false,
    updateOrganisationsLoading: false
  },
  action: any
) {
  switch (action.type) {
    case FETCH_ORGANISATIONS_BEGIN:
      return {
        ...state,
        fetchOrganisationsLoading: true,
        fetchOrganisationsError: undefined
      };
    case FETCH_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        fetchOrganisationsLoading: false,
        organisations: action.payload
      };
    case FETCH_ORGANISATIONS_FAILURE:
      return {
        ...state,
        fetchOrganisationsLoading: false,
        fetchOrganisationsError: action.payload
      };
    case UPDATE_ORGANISATIONS_BEGIN:
      return {
        ...state,
        updateOrganisationsLoading: true,
        updateOrganisationsError: undefined
      };
    case UPDATE_ORGANISATIONS_SUCCESS: 
      return {
        ...state,
        updateOrganisationsLoading: false
      };
    case UPDATE_ORGANISATIONS_FAILURE:
      return {
        ...state,
        updateOrganisationsLoading: false,
        updateOrganisationsError: action.payload
      };
    default:
      return state;
  }
}
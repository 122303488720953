import React, { useState, useEffect } from "react";
import { State } from "../../../redux/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { handleHasAdminRole } from "../../../common/utilities/roleHandler";
import { UIKit } from "@egdeconsulting/ekom_lib";
import { getUsers, updateUsers } from "../user.service";
import { IUser } from "../IUser";

const UserManagement = () => {
  const [usersToUpdate, setUsersToUpdate] = useState<IUser[]>([]);

  const { access_token, users, fetchUsersLoading, updateUsersLoading } =
    useSelector(
      (state: State) =>
        (state.auth.user && {
          access_token: state.auth.user.access_token,
          users: state.users.users,
          fetchUsersLoading: state.users.fetchUsersLoading,
          fetchUsersError: state.users.fetchUsersError,
          updateUsersLoading: state.users.updateUsersLoading,
          updateUsersError: state.users.updateUsersError,
        }) ||
        {}
    );

  const dispatch = useDispatch();

  useEffect(() => {
    handleHasAdminRole();
    dispatch<any>(getUsers(access_token || ""));
  }, [access_token, dispatch]);

  const handleBlockCheckBox = (event: any, user: IUser) => {
    const status: number = event.target.checked ? 2 : 1;

    if (usersToUpdate.includes(user)) {
      let tmpUsersToUpdate = usersToUpdate.filter((u) => u !== user);

      user.status = status;
      tmpUsersToUpdate = tmpUsersToUpdate.concat(user);

      setUsersToUpdate(tmpUsersToUpdate);
    } else {
      user.status = status;
      setUsersToUpdate(usersToUpdate.concat(user));
    }
  };

  const handleSubmit = () => {
    dispatch<any>(updateUsers(access_token || "", usersToUpdate));
  };

  if (!fetchUsersLoading && users) {
    return (
      <UIKit.Section>
        <UIKit.Container>
          <h1 className="uk-text-center">Administrer brukere</h1>
          <div>
            <table className="uk-table uk-table-striped uk-table-responsive uk-table-small">
              <thead>
                <tr>
                  <th>BRUKERID</th>
                  <th>SUBID</th>
                  <th>BLOKKERT?</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user: IUser) => {
                  return (
                    <tr key={user.userId.toString()}>
                      <td>
                        <span className="uk-hidden@m uk-margin-small-right">
                          Id:
                        </span>
                        {user.userId}
                      </td>
                      <td>
                        <span className="uk-hidden@m uk-margin-small-right">
                          SubId:
                        </span>
                        {user.subId}
                      </td>
                      <td>
                        <span className="uk-hidden@m uk-margin-small-right">
                          Blokkert?:
                        </span>
                        <input
                          className="uk-checkbox uk-margin-small-right"
                          onChange={(event) => handleBlockCheckBox(event, user)}
                          type="checkbox"
                          checked={user.status === 2 ? true : false}
                          id={user.userId.toString()}
                        />
                        Ja
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {updateUsersLoading ? (
              <UIKit.Spinner>Oppdaterer</UIKit.Spinner>
            ) : (
              <UIKit.Button
                color="primary"
                className="uk-width-small uk-align-center"
                disabled={usersToUpdate.length <= 0}
                onClick={() => handleSubmit()}
              >
                Oppdater
              </UIKit.Button>
            )}
          </div>
        </UIKit.Container>
      </UIKit.Section>
    );
  } else if (fetchUsersLoading)
    return (
      <UIKit.Container>
        <UIKit.Spinner>Laster</UIKit.Spinner>
      </UIKit.Container>
    );
  else return <div></div>;
};

export default UserManagement;

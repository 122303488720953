import { useSelector } from "react-redux";
import { State } from "../redux/rootReducer";
import { useQuery } from "@tanstack/react-query";
import {
  getNumberOfConstructionsPerMonth,
  getNumberOfOrganisationsPerMonth,
  getNumberOfProductsPerMonth,
  getNumberOfUsersPerMonth,
  getOrganisationsWithConstructionsPerMonth,
  getOrganisationsWithContactInfoPerMonth,
  getOrganisationsWithProductsPerMonth,
} from "../requests/Statistics";

const useGetProductsPerMonth = () => {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );

  return useQuery({
    queryKey: ["GetProductsPerMonth", access_token],
    queryFn: () => getNumberOfProductsPerMonth(access_token),
  });
};

const useGetConstructionsPerMonth = () => {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );

  return useQuery({
    queryKey: ["GetConstructionsPerMonth", access_token],
    queryFn: () => getNumberOfConstructionsPerMonth(access_token),
  });
};

const useGetUsersPerMonth = () => {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );

  return useQuery({
    queryKey: ["GetUsersPerMonth", access_token],
    queryFn: () => getNumberOfUsersPerMonth(access_token),
  });
};

const useGetOrganisationsPerMonth = () => {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );

  return useQuery({
    queryKey: ["GetOrganisationsPerMonth", access_token],
    queryFn: () => getNumberOfOrganisationsPerMonth(access_token),
  });
};

const useGetOrganisationsWithContactInfoPerMonth = () => {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );

  return useQuery({
    queryKey: ["GetOrganisationsWithContactInfoPerMonth", access_token],
    queryFn: () => getOrganisationsWithContactInfoPerMonth(access_token),
  });
};

const useGetOrganisationsWithProductsPerMonth = () => {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );

  return useQuery({
    queryKey: ["GetOrganisationsWithProductsPerMonth", access_token],
    queryFn: () => getOrganisationsWithProductsPerMonth(access_token),
  });
};

const useGetOrganisationsWithConstructionsPerMonth = () => {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );

  return useQuery({
    queryKey: ["GetOrganisationsWithConstructionsPerMonth", access_token],
    queryFn: () => getOrganisationsWithConstructionsPerMonth(access_token),
  });
};

export {
  useGetProductsPerMonth,
  useGetConstructionsPerMonth,
  useGetUsersPerMonth,
  useGetOrganisationsPerMonth,
  useGetOrganisationsWithContactInfoPerMonth,
  useGetOrganisationsWithProductsPerMonth,
  useGetOrganisationsWithConstructionsPerMonth,
};

/**
 * Converts Date object to formatted string (dd.mm.yyyy)
 * @param date Date object
 * @returns {string}
 */
export function getDateStringFormatted(date: Date) {
  if (date !== undefined) {
    let tmpDate = new Date(date);
    //let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(tmpDate);
    let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      tmpDate
    );
    let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      tmpDate
    );
    return month.toString() + " " + year.toString();
  }
  return "";
}

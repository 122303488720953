import { IOrganisation } from './IOrganisation';


export const FETCH_ORGANISATIONS_BEGIN = 'FETCH_ORGANISATIONS_BEGIN';
export const FETCH_ORGANISATIONS_SUCCESS = 'FETCH_ORGANISATIONS_SUCCESS';
export const FETCH_ORGANISATIONS_FAILURE = 'FETCH_ORGANISATIONS_FAILURE';

export const UPDATE_ORGANISATIONS_BEGIN = 'UPDATE_ORGANISATIONS_BEGIN';
export const UPDATE_ORGANISATIONS_SUCCESS = 'UPDATE_ORGANISATIONS_SUCCESS';
export const UPDATE_ORGANISATIONS_FAILURE = 'UPDATE_ORGANISATIONS_FAILURE';

export const fetchOrganisationsBegin = () => ({
  type: FETCH_ORGANISATIONS_BEGIN
});
export const fetchOrganisationsSuccess = (organisations: IOrganisation[]) => ({
  type: FETCH_ORGANISATIONS_SUCCESS,
  payload: organisations
});
export const fetchOrganisationsFailure = (error: string, exception: Error) => ({
  type: FETCH_ORGANISATIONS_FAILURE,
  payload: { message: error, detail: exception.message }
});

export const updateOrganisationsBegin = () => ({
  type: UPDATE_ORGANISATIONS_BEGIN
});
export const updateOrganisationsSuccess = (msg: string) => ({
  type: UPDATE_ORGANISATIONS_SUCCESS,
  payload: { message: msg }
});
export const updateOrganisationsFailure = (error: string, exception: Error) => ({
  type: UPDATE_ORGANISATIONS_FAILURE,
  payload: { message: error, detail: exception.message }
});
import React, { useState, useEffect } from "react";
import { State } from "../../../redux/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { handleHasAdminRole } from "../../../common/utilities/roleHandler";
import { UIKit } from "@egdeconsulting/ekom_lib";
import { getOrganisations, updateOrganisations } from "../organisation.service";
import { IOrganisation } from "../IOrganisation";

const OrganisationManagement = () => {
  const [organisationsToUpdate, setOrganisationsToUpdate] = useState<
    IOrganisation[]
  >([]);

  const {
    access_token,
    organisations,
    fetchOrganisationsLoading,
    updateOrganisationsLoading,
  } = useSelector(
    (state: State) =>
      (state.auth.user && {
        access_token: state.auth.user.access_token,
        organisations: state.organisations.organisations,
        fetchOrganisationsLoading:
          state.organisations.fetchOrganisationsLoading,
        updateOrganisationsLoading:
          state.organisations.updateOrganisationsLoading,
      }) ||
      {}
  );

  const dispatch = useDispatch();

  useEffect(() => {
    handleHasAdminRole();
    dispatch<any>(getOrganisations(access_token || ""));
  }, [access_token, dispatch]);

  const handleBlockCheckBox = (event: any, organisation: IOrganisation) => {
    const status: number = event.target.checked ? 2 : 1;

    if (organisationsToUpdate.includes(organisation)) {
      let tmpOrganisationsToUpdate = organisationsToUpdate.filter(
        (o) => o !== organisation
      );

      organisation.status = status;
      tmpOrganisationsToUpdate = tmpOrganisationsToUpdate.concat(organisation);

      setOrganisationsToUpdate(tmpOrganisationsToUpdate);
    } else {
      organisation.status = status;
      setOrganisationsToUpdate(organisationsToUpdate.concat(organisation));
    }
  };

  const handleSubmit = () => {
    dispatch<any>(
      updateOrganisations(access_token || "", organisationsToUpdate)
    );
  };

  const handlehideProductsInPortalChange = (
    event: any,
    organisation: IOrganisation
  ) => {
    if (organisationsToUpdate.includes(organisation)) {
      let tmpOrganisationsToUpdate = organisationsToUpdate.filter(
        (o) => o !== organisation
      );

      organisation.hideProductsInPortal = event.target.checked;
      tmpOrganisationsToUpdate = tmpOrganisationsToUpdate.concat(organisation);

      setOrganisationsToUpdate(tmpOrganisationsToUpdate);
    } else {
      organisation.hideProductsInPortal = event.target.checked;
      setOrganisationsToUpdate(organisationsToUpdate.concat(organisation));
    }
  };

  if (!fetchOrganisationsLoading && organisations) {
    return (
      <UIKit.Section>
        <UIKit.Container>
          <h1 className="uk-text-center">Administrer virksomheter</h1>
          <div>
            <table className="uk-table uk-table-striped uk-table-responsive uk-table-small">
              <thead>
                <tr>
                  <th>VIRKSOMHETSID</th>
                  <th>VIRKSOMHETSNAVN</th>
                  <th>ORGANISASJONSNUMMER</th>
                  <th>BLOKKERT?</th>
                  <th>SKJULE DATA FRA KARTSØK I PORTALEN?</th>
                </tr>
              </thead>
              <tbody>
                {organisations.map((organisation: IOrganisation) => {
                  return (
                    <tr key={organisation.organisationId.toString()}>
                      <td>
                        <span className="uk-hidden@m uk-margin-small-right">
                          Id:
                        </span>
                        {organisation.organisationId}
                      </td>
                      <td>
                        <span className="uk-hidden@m uk-margin-small-right">
                          Navn:
                        </span>
                        {organisation.organisationName}
                      </td>
                      <td>
                        <span className="uk-hidden@m uk-margin-small-right">
                          Orgnummer:
                        </span>
                        {organisation.organisationNumber}
                      </td>
                      <td>
                        <span className="uk-hidden@m uk-margin-small-right">
                          Blokkert?:
                        </span>
                        <input
                          id="blockStatus"
                          className="uk-checkbox uk-margin-small-right"
                          onChange={(event) =>
                            handleBlockCheckBox(event, organisation)
                          }
                          type="checkbox"
                          checked={organisation.status === 2 ? true : false}
                        />
                        Ja
                      </td>
                      <td>
                        <span className="uk-hidden@m uk-margin-small-right">
                          Skjule data fra kartsøk i portalen?:
                        </span>
                        <input
                          id="hideInPortal"
                          className="uk-checkbox uk-margin-small-right"
                          onChange={(event) =>
                            handlehideProductsInPortalChange(
                              event,
                              organisation
                            )
                          }
                          type="checkbox"
                          checked={organisation.hideProductsInPortal}
                        />
                        Ja
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {updateOrganisationsLoading ? (
              <UIKit.Spinner>Oppdaterer</UIKit.Spinner>
            ) : (
              <UIKit.Button
                color="primary"
                className="uk-width-small uk-align-center"
                disabled={organisationsToUpdate.length <= 0}
                onClick={() => handleSubmit()}
              >
                Oppdater
              </UIKit.Button>
            )}
          </div>
        </UIKit.Container>
      </UIKit.Section>
    );
  } else if (fetchOrganisationsLoading)
    return (
      <UIKit.Container>
        <UIKit.Spinner>Laster</UIKit.Spinner>
      </UIKit.Container>
    );
  else return <div></div>;
};

export default OrganisationManagement;

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const store = configureStore;
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const container = document.getElementById("root");
if (container !== null) {
  const root = createRoot(container);

  root.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
      </Provider>
    </QueryClientProvider>
  );

  serviceWorker.unregister();
}

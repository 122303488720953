import {
  fetchContentDataBegin,
  fetchContentDataFailure,
  fetchContentDataSuccess,
  updateContentDataBegin,
  updateContentDataSuccess,
  updateContentDataFailure,
} from "./content.actions";
import { IContent } from "./IContent";
import { NotificationMessages } from "@egdeconsulting/ekom_lib";

const baseUrl: string =
  process.env.REACT_APP_AUTH_API || "/404?REACT_APP_AUTH_API";

/**
 * Retrieves all content or the ones with the provided contentName.
 * @param access_token bearer access token
 * @param contentName optional - if provided retrieve ones with the provided contentName
 */
export function getContent(access_token: string, contentName?: string) {
  return (dispatch: any) => {
    dispatch(fetchContentDataBegin());
    return fetch(baseUrl.concat("/api/content/" + (contentName ?? "%20")), {
      headers: new Headers({ Authorization: "Bearer " + access_token }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(await response.text());
        } else return await response.json();
      })
      .then((contentList) => {
        dispatch(fetchContentDataSuccess(contentList));
        return contentList;
      })
      .catch((error: Error) =>
        dispatch(
          fetchContentDataFailure(
            NotificationMessages.CONTENT_FAILED.FETCH,
            error
          )
        )
      );
  };
}

/**
 * Updates all fields on the given content objects.
 * @param access_token bearer access token
 * @param contentList IContent with updated content object.
 */
export function updateContent(access_token: string, contentList: IContent) {
  return (dispatch: any) => {
    dispatch(updateContentDataBegin());
    return fetch(baseUrl.concat("/api/content/update"), {
      method: "post",
      body: JSON.stringify([contentList]),
      headers: new Headers({
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(await response.text());
        }
      })
      .then(() => {
        dispatch(
          updateContentDataSuccess(
            NotificationMessages.UPDATECONTENT_SUCCESS.FETCH
          )
        );
      })
      .catch((error: Error) =>
        dispatch(
          updateContentDataFailure(
            NotificationMessages.UPDATECONTENT_FAILED.FETCH,
            error
          )
        )
      );
  };
}

import { IUser, IUserRoles } from './IUser';

export const FETCH_USERS_BEGIN = 'FETCH_USERS_BEGIN';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const UPDATE_USERS_BEGIN = 'UPDATE_USERS_BEGIN';
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';
export const UPDATE_USERS_FAILURE = 'UPDATE_USERS_FAILURE';

export const FETCH_USER_ROLES_BEGIN = 'FETCH_USER_ROLES_BEGIN';
export const FETCH_USER_ROLES_SUCCESS = 'FETCH_USER_ROLES_SUCCESS';
export const FETCH_USER_ROLES_FAILURE = 'FETCH_USER_ROLES_FAILURE';

export const SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE';

export const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN
});
export const fetchUsersSuccess = (users: IUser[]) => ({
  type: FETCH_USERS_SUCCESS,
  payload: users
});
export const fetchUsersFailure = (error: string, exception: Error) => ({
  type: FETCH_USERS_FAILURE,
  payload: { message: error, detail: exception.message }
});

export const updateUsersBegin = () => ({
  type: UPDATE_USERS_BEGIN
});
export const updateUsersSuccess = (msg: string) => ({
  type: UPDATE_USERS_SUCCESS,
  payload: { message: msg }
});
export const updateUsersFailure = (error: string, exception: Error) => ({
  type: UPDATE_USERS_FAILURE,
  payload: { message: error, detail: exception.message }
});

export const fetchUserRolesBegin = () => ({
  type: FETCH_USER_ROLES_BEGIN
});
export const fetchUserRolesSuccess = (roles: IUserRoles[]) => ({
  type: FETCH_USER_ROLES_SUCCESS,
  payload: roles
});
export const fetchUserRolesFailure = (errorMsg: string, exception: Error) => ({
  type: FETCH_USER_ROLES_FAILURE,
  payload: { message: errorMsg, detail: exception.message }
});

export const setActiveRole = (role: IUserRoles) => ({
  type: SET_ACTIVE_ROLE,
  payload: role
});

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, completeLogout } from "../auth.actions";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router";
import { State } from "../../../redux/rootReducer";

const logoutRedirect = process.env.REACT_APP_LOGOUT_REDIRECT_URL || "";

const Logout = () => {
  const dispatch = useDispatch();

  const error = useSelector((state: State) => state.auth.error);
  const awaitingLogout = useSelector(
    (state: State) => state.auth.awaitingLogout
  );

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/logout/response") {
      dispatch<any>(completeLogout());
    } else {
      dispatch<any>(logout());
    }
  }, [dispatch, location.pathname]);

  if (error) {
    return <>{error}</>;
  } else {
    if (logoutRedirect === "") return <Navigate to={{ pathname: "/" }} />;
    else {
      if (!awaitingLogout) {
        window.location.href = logoutRedirect;
        return <div></div>;
      } else return <div></div>;
    }
  }
};

export default Logout;

import React from 'react'

export function UserManagement() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 36 35" fill="none">
            <path d="M23.7311 12.4617C14.5085 12.4617 12.7519 22.154 13.0263 27.0002H34.4352C34.7099 22.154 32.9536 12.4617 23.7311 12.4617Z" fill="white" stroke="#3028AA" strokeWidth="2" />
            <circle cx="23.7308" cy="6.57694" r="5.57694" fill="white" stroke="#3028AA" strokeWidth="2" />
            <path d="M13.5003 16.5161C2.75724 16.5161 0.710943 27.8064 1.03068 33.4516H25.9693C26.2892 27.8064 24.2434 16.5161 13.5003 16.5161Z" fill="white" stroke="#3028AA" strokeWidth="2" />
            <circle cx="13.5002" cy="9.66129" r="6.66129" fill="white" stroke="#3028AA" strokeWidth="2" />
        </svg>
    )
}
import { NotificationMessages } from "@egdeconsulting/ekom_lib";
import { UserManager, User } from "oidc-client";
import { ASCII32inString2Space } from "../utilities/parser";
import { deleteActiveRole } from "../utilities/roleHandler";

let settings = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT,
  redirect_uri: window.location.origin + "/login/response",
  post_logout_redirect_uri: window.location.origin + "/logout/response",
  response_type: "code",
  scope: ASCII32inString2Space(process.env.REACT_APP_OIDC_SCOPE || ""),
  acr_values: "idporten-loa-substantial",
  ui_locales: "nb",
  loadUserInfo: false,
  revokeAccessTokenOnSignout: false,
  extraQueryParams: {
    resource: process.env.REACT_APP_OIDC_RESOURCE,
  },
};

const manager = new UserManager(settings);

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

const loginSuccess = (user: User) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

const loginFailed = (errorMsg: string, exception: Error) => ({
  type: LOGIN_FAILED,
  payload: { message: errorMsg, detail: exception.message },
});

const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

const logoutFailed = (error: Error) => ({
  type: LOGOUT_FAILED,
  payload: error,
});

export const login = () => (dispatch: Function) => {
  manager.getUser().then((user: User | null) => {
    if (!!user && !user.expired) {
      dispatch(loginSuccess(user));
    } else {
      manager
        .signinRedirect()
        .catch((error: Error) =>
          dispatch(loginFailed(NotificationMessages.LOGIN_FAILED.FETCH, error))
        );
    }
  });
};

export const completeLogin = () => (dispatch: Function) => {
  manager
    .signinRedirectCallback()
    .then(() => {
      manager.getUser().then((value: User | null) => {
        if (value === null) throw new Error("Failed to get user");
        dispatch(loginSuccess(value));
      });
    })
    .catch((error: Error) => {
      if (error.message !== "No state in response")
        dispatch(loginFailed(NotificationMessages.LOGIN_FAILED.FETCH, error));
    });
};

export const logout = () => (dispatch: Function) => {
  manager
    .signoutRedirect()
    .catch((error: Error) => dispatch(logoutFailed(error)));
};

export const completeLogout = () => (dispatch: Function) => {
  deleteActiveRole();
  manager
    .removeUser()
    .then(() => {
      return dispatch(logoutSuccess());
    })
    .catch((error) => dispatch(logoutFailed(error)));
};

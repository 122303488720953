import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../redux/rootReducer";
import { handleHasAdminRole } from "../../common/utilities/roleHandler";
import { UIKit, ModalView } from "@egdeconsulting/ekom_lib";
import { getContent, updateContent } from "../content.service";
import { IContent } from "../IContent";

export const Content: React.FC = () => {
  const contentList = useSelector((state: State) => state.content.contentList);
  const fetchContentLoading = useSelector(
    (state: State) => state.content.fetchContentLoading
  );
  const updateContentLoading = useSelector(
    (state: State) => state.content.updateContentLoading
  );
  const access_token = useSelector(
    (state: State) => state.auth.user?.access_token || ""
  );

  const [contentBeingEdited, setContentBeingEdited] = useState<
    IContent | undefined
  >(undefined);
  const [showConfirmUpdateModal, setShowConfirmUpdateModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    handleHasAdminRole();
    dispatch<any>(getContent(access_token));
  }, [access_token, dispatch]);

  const onClickEdit = (content: IContent) => {
    setContentBeingEdited(content);
  };

  const handleEditContentInputChange = (event: any) => {
    if (event.target.name === "value") {
      setContentBeingEdited({
        contentName: contentBeingEdited?.contentName,
        contentValue: event.target.value,
        contentId: contentBeingEdited?.contentId,
        contentDescription: contentBeingEdited?.contentDescription,
        contentType: contentBeingEdited?.contentType,
      });
    } else if (event.target.name === "description") {
      setContentBeingEdited({
        contentName: contentBeingEdited?.contentName,
        contentDescription: event.target.value,
        contentId: contentBeingEdited?.contentId,
        contentValue: contentBeingEdited?.contentValue,
        contentType: contentBeingEdited?.contentType,
      });
    }
  };

  const handleEditContentClose = () => {
    setContentBeingEdited(undefined);
    setShowConfirmUpdateModal(false);
  };

  const handleEditContentSave = () => {
    setShowConfirmUpdateModal(true);
  };

  const handleUpdateContent = () => {
    if (contentBeingEdited) {
      dispatch<any>(updateContent(access_token, contentBeingEdited));
    }
    setShowConfirmUpdateModal(false);
    setContentBeingEdited(undefined);
  };

  if (updateContentLoading) {
    return (
      <UIKit.Container>
        <UIKit.Spinner>Oppdaterer innhold</UIKit.Spinner>
      </UIKit.Container>
    );
  } else if (fetchContentLoading)
    return (
      <UIKit.Container>
        <UIKit.Spinner>Henter innhold</UIKit.Spinner>
      </UIKit.Container>
    );
  else if (!fetchContentLoading && contentList) {
    // originalContentFromPropsBeingEdited is the related content object in props which is currently being updated.
    const originalContentFromPropsBeingEdited = contentList.find(
      (c) => c.contentId === contentBeingEdited?.contentId
    );

    //If field is defined in state, user is editing. If not user has not edited fields yet - use value from props/api
    // const value =
    //   contentBeingEdited?.contentValue ??
    //   originalContentFromPropsBeingEdited?.contentValue;
    // const description =
    //   contentBeingEdited?.contentDescription ??
    //   originalContentFromPropsBeingEdited?.contentDescription;
    const sortedContentList = contentList.sort(
      (next: IContent, prev: IContent) => {
        return (next.contentId ? next.contentId : 0) <
          (prev.contentId ? prev.contentId : 0)
          ? -1
          : 1;
      }
    );

    return (
      <UIKit.Section>
        <UIKit.Container>
          <h1>Administrer innhold i Ekomportalen</h1>
          <table className="uk-table uk-table-divider uk-table-responsive uk-table-small">
            <thead>
              <tr>
                <th>ID</th>
                <th>INNHOLD</th>
                <th>BESKRIVELSE</th>
                <th>REDIGER</th>
              </tr>
            </thead>
            <tbody>
              {sortedContentList.map((content: IContent, index: number) => (
                <tr key={index}>
                  <td>{content.contentId}</td>
                  <td>
                    {content.contentType === 2 ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={content.contentValue}
                      >
                        {content.contentValue}
                      </a>
                    ) : (
                      content.contentValue
                    )}
                  </td>
                  <td>{content.contentDescription}</td>
                  <td>
                    <button
                      className="uk-button uk-button-default"
                      onClick={() => onClickEdit({ ...content })}
                    >
                      Rediger
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <ModalView
            show={!!contentBeingEdited && !showConfirmUpdateModal}
            size="xl"
            modalTitle={"Rediger innhold"}
            onClose={() => handleEditContentClose()}
            onSubmit={() => handleEditContentSave()}
            submitButtonText="Lagre"
            closeButtonText="Avbryt"
          >
            <UIKit.Section>
              <UIKit.Container>
                <h4>ID: {contentBeingEdited?.contentId}</h4>
                <div className="uk-margin-small-right uk-margin-top">
                  <label>
                    Type:{" "}
                    {contentBeingEdited?.contentType === 1 ? "tekst" : "lenke"}
                  </label>
                  <div className="uk-margin">
                    <label htmlFor="description" className="uk-h5">
                      {" "}
                      Beskrivelse (vises ikke for brukere)
                    </label>
                    <textarea
                      name="description"
                      className="uk-textarea "
                      rows={6}
                      defaultValue={
                        contentBeingEdited?.contentDescription ??
                        originalContentFromPropsBeingEdited?.contentDescription
                      }
                      onChange={handleEditContentInputChange}
                    />
                  </div>
                  <div className="uk-margin">
                    <label htmlFor="value" className="uk-h5">
                      {" "}
                      Innhold som vises til brukerne
                    </label>
                    <textarea
                      name="value"
                      className="uk-textarea "
                      rows={6}
                      defaultValue={
                        contentBeingEdited?.contentValue ??
                        originalContentFromPropsBeingEdited?.contentValue
                      }
                      onChange={handleEditContentInputChange}
                    />
                  </div>
                </div>
              </UIKit.Container>
            </UIKit.Section>
          </ModalView>

          <ModalView
            show={showConfirmUpdateModal}
            modalTitle="Bekreft endring"
            size="xl"
            onClose={() => handleEditContentClose()}
            onSubmit={() => handleUpdateContent()}
            submitButtonText="Ja, lagre endring"
          >
            <UIKit.Section>
              <UIKit.Container>
                <div
                  className="uk-margin"
                  onClick={() => setShowConfirmUpdateModal(false)}
                >
                  <span uk-icon="icon: arrow-left; ratio: 2"></span>
                </div>
                <h4>ID: {contentBeingEdited?.contentId}</h4>
                <h5>Er du sikker på at du vil endre innholdet fra</h5>
                <div className="uk-margin">
                  <div className="uk-background-muted uk-padding">
                    {originalContentFromPropsBeingEdited?.contentValue}
                  </div>
                </div>
                <h5>til</h5>
                <div className="uk-margin">
                  <div className="uk-background-muted uk-padding">
                    {contentBeingEdited?.contentValue}
                  </div>
                </div>
              </UIKit.Container>
            </UIKit.Section>
          </ModalView>
        </UIKit.Container>
      </UIKit.Section>
    );
  } else return <div></div>;
};

export default Content;

import { hasRole } from '@egdeconsulting/ekom_lib';

const adminRoleName = process.env.REACT_APP_ADMIN_ROLE_NAME || 'admin';
const landingSite = process.env.REACT_APP_LANDING_SITE_URL || '';
/**
 * Retrieves activerole from sessionstorage and checks if the role is admin.
 * If activerole is empty or does not contain admin role, set window location to landingSite.
 */
export function handleHasAdminRole() {
    var activeRole = JSON.parse(sessionStorage.getItem('active_role') || '{}');
    if (Object.keys(activeRole).length > 0) {
        const hasAdminRole = hasRole(adminRoleName, activeRole)
        if (hasAdminRole) return;
    }
    window.location.href = landingSite;
}

export function deleteActiveRole() {
    var hasRole = Object.keys(JSON.parse(sessionStorage.getItem('active_role') || '{}')).length > 0;
    if (hasRole) sessionStorage.removeItem('active_role');
}
import {
FETCH_CONTENTDATA_BEGIN,
FETCH_CONTENTDATA_FAILURE,
FETCH_CONTENTDATA_SUCCESS,
UPDATE_CONTENTDATA_BEGIN,
UPDATE_CONTENTDATA_FAILURE,
UPDATE_CONTENTDATA_SUCCESS
} from './content.actions';
import { IContent } from './IContent';

export type ContentState = {
    contentList?: IContent[];
    fetchContentLoading: boolean;
    fetchContentError?: string;
    updateContentLoading: boolean;
    updateContentError?: string;
};

export default function contentReducer(
    state: ContentState = {
        fetchContentLoading: false,
        updateContentLoading: false
    },
    action: any
) {
    switch (action.type) {
        case FETCH_CONTENTDATA_BEGIN:
            return {
                ...state,
                fetchContentLoading: true,
                fetchContentError: undefined
            };
        case FETCH_CONTENTDATA_SUCCESS:
            return {
                ...state,
                fetchContentLoading: false,
                contentList: action.payload
            };
        case FETCH_CONTENTDATA_FAILURE:
            return {
                ...state,
                fetchContentLoading: false,
                fetchContentError: action.payload,
            };
        case UPDATE_CONTENTDATA_BEGIN:
            return {
                ...state,
                updateContentLoading: true,
                updateContentError: undefined
            };
        case UPDATE_CONTENTDATA_SUCCESS:
            return {
                ...state,
                updateContentLoading: false
            };
        case UPDATE_CONTENTDATA_FAILURE:
            return {
                ...state,
                updateContentLoading: false,
                updateContentError: action.payload
            };
        default:
            return state;
    }
}

import { combineReducers } from "redux";
import auth, { AuthState } from "../common/auth/auth.reducer";
import content, { ContentState } from "../content/content.reducer";
import users, { UserState } from "../management/user/user.reducer";
import limits, { LimitsState } from "../management/limits/limits.reducers";
import organisations, {
  OrganisationState,
} from "../management/organisation/organisation.reducers";
import { NotificationState, notification } from "@egdeconsulting/ekom_lib";

export type State = {
  auth: AuthState;
  content: ContentState;
  organisations: OrganisationState;
  users: UserState;
  limits: LimitsState;
  notification: NotificationState;
};

export default combineReducers<State>({
  auth,
  content,
  users,
  limits,
  organisations,
  notification,
});

import React, { ReactElement } from "react";
import {
  Header,
  Notification,
  NotificationItem,
  clearNotifications,
  removeNotification,
  Footer,
} from "@egdeconsulting/ekom_lib";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../../redux/rootReducer";
import { IUserRoles } from "../../../management/user/IUser";
import isAuthenticated from "../../auth/isAuthenticated";

const personvernSite = process.env.REACT_APP_PERSONVERN_URL || "";
const kontaktSite = process.env.REACT_APP_KONTAKT_URL || "";
const liabilitySite = process.env.REACT_APP_NKOMANSVAR_URL || "";

export const Root = (prop: { children: ReactElement }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state: State) => state.notification?.notifications
  );
  const userAuthenticated = useSelector((state: State) =>
    isAuthenticated(state.auth.user)
  );
  const activeRole: IUserRoles = JSON.parse(
    sessionStorage.getItem("active_role") || "{}"
  );
  const activeUserName: string =
    sessionStorage.getItem("active_user_name") || "";

  const removeNotif = (current: NotificationItem) =>
    dispatch(removeNotification(current));
  const clearNotifs = () => dispatch(clearNotifications());

  return (
    <div>
      <Header
        title="EKOMPORTALEN"
        loggedIn={userAuthenticated}
        loggedInAsOrganisation={
          activeRole?.type && activeRole?.type === "Organization"
            ? activeRole.name
            : ""
        }
        loggedInName={activeUserName}
      />
      <Notification
        notifications={notifications}
        clearNotifications={clearNotifs}
        removeNotification={removeNotif}
      />
      <div id="main">{prop.children}</div>
      <Footer
        links={[
          { link: personvernSite, linkText: "Personvern" },
          { link: kontaktSite, linkText: "Kontakt" },
          { link: liabilitySite, linkText: "Nkoms ansvar" },
        ]}
      />
    </div>
  );
};

export default Root;

import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from "recharts";
import { ICountPerMonth } from "../types/ICountPerMonth";
import { getDateStringFormatted } from "../common/utilities/dateParser";

const MonthlyChart = (props: { data: ICountPerMonth[] | undefined }) => {
  const [cumulativeData, setCumulativeData] = useState<any>([]);

  const formatXAxis = (tickItem: Date) => {
    return getDateStringFormatted(new Date(tickItem));
  };
  const computeCumulativeData = (data: ICountPerMonth[]) => {
    let cumulativeSum = 0;
    return data.map((entry) => {
      cumulativeSum += entry.count;
      return { ...entry, antall: cumulativeSum };
    });
  };
  useEffect(() => {
    if (props.data === undefined) return;

    setCumulativeData(computeCumulativeData(props.data));
  }, [props.data]);

  return (
    <ResponsiveContainer>
      <LineChart
        data={cumulativeData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 75,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="created"
          angle={90}
          textAnchor="start"
          dy={10}
          tickFormatter={formatXAxis}
        />
        <YAxis />
        <Tooltip labelFormatter={formatXAxis} />

        <Line dataKey="antall" stroke="#82ca9d" strokeWidth={3} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MonthlyChart;

import {
  FETCH_LIMITS_BEGIN,
  FETCH_LIMITS_FAILURE,
  FETCH_LIMITS_SUCCESS,
  UPDATE_LIMITS_BEGIN,
  UPDATE_LIMITS_FAILURE,
  UPDATE_LIMITS_SUCCESS
} from './limits.actions';
import { ILimits } from './ILimits';

export type LimitsState = {
  limits?: ILimits;
  fetchLimitsLoading: boolean;
  fetchLimitsError?: string;
  updateLimitsLoading: boolean;
  updateLimitsError?: string;
};

export default function limitsReducer(
  state: LimitsState = {
    fetchLimitsLoading: false,
    updateLimitsLoading: false
  },
  action: any
) {
  switch (action.type) {
    case FETCH_LIMITS_BEGIN:
      return {
        ...state,
        fetchLimitsLoading: true,
        fetchLimitsError: undefined
      };
    case FETCH_LIMITS_SUCCESS:
      return {
        ...state,
        fetchLimitsLoading: false,
        limits: action.payload
      };
    case FETCH_LIMITS_FAILURE:
      return {
        ...state,
        fetchLimitsLoading: false,
        fetchLimitsError: action.payload
      };
    case UPDATE_LIMITS_BEGIN:
      return {
        ...state,
        updateLimitsLoading: true,
        updateLimitsError: undefined
      };
    case UPDATE_LIMITS_SUCCESS:
      return {
        ...state,
        updateLimitsLoading: false,
      };
    case UPDATE_LIMITS_FAILURE:
      return {
        ...state,
        updateLimitsLoading: false,
        updateLimitsError: action.payload
      };
    default:
      return state;
  }
}
import React from 'react'

export function UseManagement() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="41" viewBox="0 0 50 41" fill="none">
            <rect x="28.6924" y="12.3076" width="20.5128" height="2" fill="#3028AA" />
            <rect x="30.7432" y="20.5127" width="18.4615" height="2" fill="#3028AA" />
            <rect x="34.8457" y="28.7178" width="14.359" height="2" fill="#3028AA" />
            <path d="M16.8979 18.4614C3.23485 18.4614 0.632379 32.8204 1.03902 39.9999H32.7558C33.1627 32.8204 30.5609 18.4614 16.8979 18.4614Z" fill="white" stroke="#3028AA" strokeWidth="2" />
            <circle cx="16.8979" cy="9.74359" r="8.74359" fill="white" stroke="#3028AA" strokeWidth="2" />
        </svg>
    )
}
import { IUser } from './IUser';
import {
  fetchUsersBegin,
  fetchUsersFailure,
  fetchUsersSuccess,
  updateUsersBegin,
  updateUsersFailure,
  updateUsersSuccess,
  fetchUserRolesBegin,
  fetchUserRolesFailure,
  fetchUserRolesSuccess
} from './user.action';
import { NotificationMessages } from '@egdeconsulting/ekom_lib';

const baseUrl: string = process.env.REACT_APP_AUTH_API || '/404?REACT_APP_AUTH_API';

/**
 * Retrieve all users
 * @param access_token bearer access token
 */
export function getUsers(access_token: string) {
  return (dispatch: any) => {
    dispatch(fetchUsersBegin());
    return fetch(baseUrl.concat('/api/user/'), {
      headers: new Headers({ Authorization: 'Bearer ' + access_token })
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error(await response.text())
        }
        else return await response.json();
      })
      .then(users => {
        dispatch(fetchUsersSuccess(users));
        return users;
      })
      .catch((error: Error) => dispatch(fetchUsersFailure(NotificationMessages.GETUSER_FAILED.FETCH, error)));
  }
}

/**
 * Updates all fields on the given user objects
 * @param access_token bearer access token
 * @param users array of users with updated user objects.
 */
export function updateUsers(access_token: string, users: IUser[]) {
  return (dispatch: any) => {
    dispatch(updateUsersBegin());
    return fetch(baseUrl.concat('/api/user/'), {
      method: 'post',
      body: JSON.stringify(users),
      headers: new Headers({
        Authorization: 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      })
    }).then(async response => {
      if (!response.ok) {
        throw new Error(await response.text())
      }
    })
      .then(() => {
        dispatch(updateUsersSuccess(NotificationMessages.UPDATEUSER_SUCCESS.FETCH));
      })
      .catch((error: Error) => dispatch(updateUsersFailure(NotificationMessages.UPDATEUSER_FAILED.FETCH, error)));
  }
}

/**
 * Fetches roles for a logged in user
 * @param access_token bearer access token
 */
 export function getUserRoles(access_token: string) {
  return (dispatch: any) => {
    dispatch(fetchUserRolesBegin());
    return fetch(baseUrl.concat('/api/user/GetUserRoles'), {
      headers: new Headers({
        'Authorization': "Bearer " + access_token,
        'Cache-Control': 'no-cache',
        'Accept': 'application/json'
      })
    })
      .then(async response => {
        if (!response.ok) {
          throw new Error(await response.text())
        }
        else return await response.json()
      })
      .then(json => {
        dispatch(fetchUserRolesSuccess(json));
      })
      .catch((error: Error) => dispatch(fetchUserRolesFailure(NotificationMessages.ROLES_FAILED.FETCH, error)));
  }
}
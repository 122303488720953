import React from "react";

export function Statistics() {
  return (
    <svg
      width="66"
      height="41"
      viewBox="0 0 66 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 21.5L17.5 5L30 17.5L46.5 1"
        stroke="#3028AA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { ICountPerMonth } from "../types/ICountPerMonth";

const baseUrl: string =
  process.env.REACT_APP_AUTH_API || "/404?REACT_APP_AUTH_API";

/**
 * Fetches number of constructionwork added per month.
 * @param token oidc access token.
 */
async function getNumberOfConstructionsPerMonth(
  token: string
): Promise<ICountPerMonth[]> {
  const url = baseUrl.concat(`/api/Statistics/NumberOfConstructionsPerMonth`);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

/**
 * Fetches number of products added per month.
 * @param token oidc access token.
 */
async function getNumberOfProductsPerMonth(
  token: string
): Promise<ICountPerMonth[]> {
  const url = baseUrl.concat(`/api/Statistics/NumberOfProductsPerMonth`);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

/**
 * Fetches number of users created per month.
 * @param token oidc access token.
 */
async function getNumberOfUsersPerMonth(
  token: string
): Promise<ICountPerMonth[]> {
  const url = baseUrl.concat(`/api/Statistics/NumberOfUsersPerMonth`);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

/**
 * Fetches number of organisations added per month.
 * @param token oidc access token.
 */
async function getNumberOfOrganisationsPerMonth(
  token: string
): Promise<ICountPerMonth[]> {
  const url = baseUrl.concat(`/api/Statistics/NumberOfOrganisationsPerMonth`);
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

/**
 * Fetches organisations that have entered contactinformation.
 * @param token oidc access token.
 */
async function getOrganisationsWithContactInfoPerMonth(
  token: string
): Promise<ICountPerMonth[]> {
  const url = baseUrl.concat(
    `/api/Statistics/NumberOfOrganisationsWithContactInfoPerMonth`
  );
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

/**
 * Fetches organisations with products registered.
 * @param token oidc access token.
 */
async function getOrganisationsWithProductsPerMonth(
  token: string
): Promise<ICountPerMonth[]> {
  const url = baseUrl.concat(
    `/api/Statistics/NumberOfOrganisationsWithProductsPerMonth`
  );
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

/**
 * Fetches organisations with construction work registered.
 * @param token oidc access token.
 */
async function getOrganisationsWithConstructionsPerMonth(
  token: string
): Promise<ICountPerMonth[]> {
  const url = baseUrl.concat(
    `/api/Statistics/NumberOfOrganisationsWithConstructionsPerMonth`
  );
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

export {
  getNumberOfConstructionsPerMonth,
  getNumberOfProductsPerMonth,
  getNumberOfUsersPerMonth,
  getNumberOfOrganisationsPerMonth,
  getOrganisationsWithContactInfoPerMonth,
  getOrganisationsWithProductsPerMonth,
  getOrganisationsWithConstructionsPerMonth,
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/rootReducer";
import { handleHasAdminRole } from "../../../common/utilities/roleHandler";
import { UIKit, ValidationWarning } from "@egdeconsulting/ekom_lib";
import { getLimits, updateLimits } from "../limits.service";
import { ILimits } from "../ILimits";

const Limits: React.FC = () => {
  const limits = useSelector((state: State) => state.limits.limits);
  const fetchLimitsLoading = useSelector(
    (state: State) => state.limits.fetchLimitsLoading
  );
  const updateLimitsLoading = useSelector(
    (state: State) => state.limits.updateLimitsLoading
  );
  const access_token = useSelector(
    (state: State) => state.auth.user?.access_token || ""
  );

  const [limitsToUpdate, setLimitsToUpdate] = useState<ILimits | undefined>(
    undefined
  );

  const dispatch = useDispatch();

  const handleMaxReqsChange = (event: any) => {
    const newMaxReqsValue = parseInt(event.target.value);
    if (limits) {
      limits.maxNumberOfDailyRequests = newMaxReqsValue;
      setLimitsToUpdate(limits);
    }
  };

  const handleMinSearchAreaChange = (event: any) => {
    const newMinSearchAreaValue = parseInt(event.target.value);
    if (limits) {
      limits.minSearchAreaSquareMeter = newMinSearchAreaValue;
      setLimitsToUpdate(limits);
    }
  };

  const handleMaxSearchAreaChange = (event: any) => {
    const newMaxSearchAreaValue = parseInt(event.target.value);
    if (limits) {
      limits.maxSearchAreaSquareMeter = newMaxSearchAreaValue;
      setLimitsToUpdate(limits);
    }
  };

  const handleSubmit = () => {
    if (limitsToUpdate) {
      dispatch<any>(updateLimits(access_token, limitsToUpdate));
    }
  };

  useEffect(() => {
    handleHasAdminRole();
    dispatch<any>(getLimits(access_token));
  }, [access_token, dispatch]);

  if (!fetchLimitsLoading && limits) {
    const maxReqsValueToRender =
      limitsToUpdate?.maxNumberOfDailyRequests ??
      limits.maxNumberOfDailyRequests;
    const maxSearchAreaSquareMeter =
      limitsToUpdate?.maxSearchAreaSquareMeter ??
      limits.maxSearchAreaSquareMeter;
    const minSearchAreaSquareMeter =
      limitsToUpdate?.minSearchAreaSquareMeter ??
      limits.minSearchAreaSquareMeter;
    return (
      <UIKit.Section>
        <UIKit.Container>
          <h1 className="uk-text-center">Administrer bruk</h1>
          <div>
            <label htmlFor="maxReqs" className="uk-h4">
              Maks antall tillatte forespørsler per døgn (henting av
              infrastrukturdata)
            </label>
            <input
              id="maxReqs"
              type="number"
              min={1}
              defaultValue={maxReqsValueToRender}
              onChange={(e) => handleMaxReqsChange(e)}
              className="uk-display-block uk-input uk-width-small"
            />
            {(maxReqsValueToRender.toString() === "NaN" ||
              maxReqsValueToRender < 1) && (
              <ValidationWarning text="Maks antall søk per døgn må være større enn 0" />
            )}
            {maxReqsValueToRender > 2147483647 && (
              <ValidationWarning text="Verdien er for stor" />
            )}
          </div>
          <div className="uk-margin-large">
            <label htmlFor="minSearch" className="uk-h4">
              Grenser i kartsøk - minimum søkeareal - m²
            </label>
            <input
              id="minSearch"
              type="number"
              min={1}
              defaultValue={minSearchAreaSquareMeter}
              onChange={(e) => handleMinSearchAreaChange(e)}
              className="uk-display-block uk-input uk-width-small"
            />
            {(minSearchAreaSquareMeter.toString() === "NaN" ||
              minSearchAreaSquareMeter < 1) && (
              <ValidationWarning text="Minimum søkeareal må være større enn 0" />
            )}
            {minSearchAreaSquareMeter > 2147483647 && (
              <ValidationWarning text="Verdien er for stor" />
            )}
          </div>
          <div className="uk-margin-large">
            <label htmlFor="maxSearchArea" className="uk-h4">
              Grenser i kartsøk - maksimum søkeareal - m²
            </label>
            <input
              id="maxSearchArea"
              type="number"
              min={1}
              defaultValue={maxSearchAreaSquareMeter}
              onChange={(e) => handleMaxSearchAreaChange(e)}
              className="uk-display-block uk-input uk-width-small"
            />
            {(maxSearchAreaSquareMeter.toString() === "NaN" ||
              maxSearchAreaSquareMeter < 1) && (
              <ValidationWarning text="Maks søkeareal må være større enn 0" />
            )}
            {maxSearchAreaSquareMeter > 2147483647 && (
              <ValidationWarning text="Verdien er for stor" />
            )}
          </div>
          <div>
            <br />
            {updateLimitsLoading ? (
              <UIKit.Spinner>Oppdaterer</UIKit.Spinner>
            ) : (
              <UIKit.Button
                disabled={
                  limitsToUpdate === undefined ||
                  maxReqsValueToRender.toString() === "NaN" ||
                  maxReqsValueToRender < 1 ||
                  maxReqsValueToRender > 2147483647 ||
                  minSearchAreaSquareMeter.toString() === "NaN" ||
                  minSearchAreaSquareMeter < 1 ||
                  minSearchAreaSquareMeter > 2147483647 ||
                  maxSearchAreaSquareMeter.toString() === "NaN" ||
                  maxSearchAreaSquareMeter < 1 ||
                  maxSearchAreaSquareMeter > 2147483647
                }
                color="primary"
                className="uk-width-medium"
                onClick={() => handleSubmit()}
              >
                Oppdater
              </UIKit.Button>
            )}
          </div>
        </UIKit.Container>
      </UIKit.Section>
    );
  } else if (fetchLimitsLoading)
    return (
      <UIKit.Container>
        <UIKit.Spinner>Laster</UIKit.Spinner>
      </UIKit.Container>
    );
  else return <div></div>;
};

export default Limits;
